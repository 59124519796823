import React, { createContext, useContext, useState } from 'react';

const SidebarContext = createContext();

export const useSidebar = () => {
    const context = useContext(SidebarContext);
    if (!context) {
        throw new Error('useSidebar must be used within a SidebarProvider');
    }
    return context;
};
export const SidebarProvider = ({ children }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [title, setTitle] = useState('');
    const [component, setComponent] = useState(null);
    const [backgroundColor, setBackgroundColor] = useState('#FFFFFF');
    const [showOptions, setShowOptions] = useState(false);
    const [taskId, setTaskId] = useState(null); // Add this for storing taskId

    const openSidebar = (title, component, bgColor = '#FFFFFF', showOptions = false, id = null) => {
        setTitle(title);
        setComponent(component);
        setBackgroundColor(bgColor);
        setShowOptions(showOptions);
        setTaskId(id); // Store the taskId instead of the delete function
        setIsOpen(true);
    };

    const closeSidebar = () => {
        setIsOpen(false);
        setTitle('');
        setComponent(null);
        setShowOptions(false);
        setTaskId(null);
    };

    return (
        <SidebarContext.Provider value={{
            isOpen,
            title,
            component,
            backgroundColor,
            showOptions,
            taskId, // Add this to context
            openSidebar,
            closeSidebar
        }}>
            {children}
        </SidebarContext.Provider>
    );
};
import { createSlice } from '@reduxjs/toolkit';

// Load tasks from localStorage
const loadTasksFromLocalStorage = () => {
    const storedTasks = localStorage.getItem('Tasks');
    return storedTasks ? JSON.parse(storedTasks) : [];
};

const taskSlice = createSlice({
    name: 'tasks',
    initialState: {
        tasks: loadTasksFromLocalStorage(),
    },
    reducers: {
        setTask: (state, action) => {
            state.tasks = action.payload;
            localStorage.setItem("Tasks", JSON.stringify(state.tasks));
        },
        addTask: (state, action) => {
            // Ensure a fresh array for tags when adding a new task
            const newTask = {
                ...action.payload,
                tags: action.payload.tags || [], // Set tags to an empty array if undefined
            };

            // Check for duplicates before adding
            const taskExists = state.tasks.some(task => task.id === newTask.id);
            if (!taskExists) {
                state.tasks = [newTask, ...state.tasks];
                localStorage.setItem("Tasks", JSON.stringify(state.tasks)); // Sync with localStorage
            }
        },
        updateTask: (state, action) => {
            const { id, field, value } = action.payload;
            state.tasks = state.tasks.map(task =>
                task.id === id ? { ...task, [field]: value } : task
            );
            localStorage.setItem("Tasks", JSON.stringify(state.tasks));
        },
        updateTaskTags: (state, action) => {
            const { taskId, tags } = action.payload;
            const taskIndex = state.tasks.findIndex(task => task.id === taskId);
            if (taskIndex !== -1) {
                // Only update the tags without affecting other properties
                state.tasks[taskIndex] = { ...state.tasks[taskIndex], tags: tags || [] };
                localStorage.setItem("Tasks", JSON.stringify(state.tasks));
            }
        },
        deleteTask: (state, action) => {
            const taskId = action.payload;
            state.tasks = state.tasks.filter(task => task.id !== taskId);
            localStorage.setItem("Tasks", JSON.stringify(state.tasks));
        },
    },
});

export const { setTask, addTask, updateTask, updateTaskTags,deleteTask } = taskSlice.actions;
export default taskSlice.reducer;

import React, { useState, useEffect } from "react";
import { DayPicker } from "react-day-picker";
import CalenderIcon from "../../../assets/images/svg/Calender.svg"; 
import Dropdown from "../CustomDropDown/CustomDropDown"; 
import './Style.css'; 
import "react-day-picker/style.css";

function MyDatePicker({ selectedDate, onChange, disabled = false }) {
  const [selected, setSelected] = useState(null);
  const [currentMonth, setCurrentMonth] = useState(new Date());
  const [isChildClosedFromParent, setIsChildClosedFromParent] = useState(false);

    // Update selected date when prop changes
    useEffect(() => {
        if (selectedDate) {
            try {
                const date = new Date(selectedDate);
                if (!isNaN(date.getTime())) {
                    setSelected(date);
                    setCurrentMonth(date);
                }
            } catch (error) {
                console.error("Invalid date:", selectedDate);
                setSelected(null);
            }
        } else {
            setSelected(null);
        }
    }, [selectedDate]);

  const handleCloseChildFromParent = () => {
    setIsChildClosedFromParent(true);
  };

  const handleDateSelect = (date) => {
    if (disabled) return;
        
    setSelected(date);
    if (date) {
        setCurrentMonth(date);
    }
    handleCloseChildFromParent();

        if (onChange) {
            onChange(date ? date.toISOString() : null);
        }
  };

  const formatDate = (date) => {
    if (!date) return '';

    const today = new Date();
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');

    if (date.toDateString() === today.toDateString()) {
      return 'Today';
    } else if (date.toDateString() === new Date(today.setDate(today.getDate() + 1)).toDateString()) {
      return 'Tomorrow';
    } else if (date.toDateString() === new Date(today.setDate(today.getDate() - 2)).toDateString()) {
      return 'Yesterday';
    }

    return `${day}/${month}`; 
  };

  return (
    <div className={`date-picker ${disabled ? 'date-picker--disabled' : ''}`}>
      <Dropdown
        close={isChildClosedFromParent}
        disabled={disabled}
        trigger={
          selected ? (
            <span>{formatDate(selected)}</span>
          ) : (
            <img
              src={CalenderIcon}
              alt="Open Calendar"
              style={{ cursor: disabled ? "not-allowed" : "pointer", opacity: disabled ? 0.5 : 1 }}
            />
          )
        }
        // indexrow={indexrow}
      >
        <div className="calender">
          <DayPicker
            captionLayout="dropdown"
            startMonth={new Date(2015, 6)}
            endMonth={new Date(2025, 9)}
            mode="single"
            selected={selected}
            onSelect={handleDateSelect}
            showOutsideDays
            month={currentMonth}
          />
        </div>
      </Dropdown>
    </div>
  );
}

export default MyDatePicker;

// TaskDetails.js
import React from "react";
import './taskDetails.css';
import Arrow from '../../assets/images/svg/Left_arrow.svg'; // Adjust path if needed
import Comments from "../Templates/CommentSection/Comment";
import Tags from "../Atom/Tags/tagsV2";
import Badge from "../Atom/Badge/badge";
import ImageGallery from "../Atom/Image/Image";
import AssigneeIcon from "../../assets/images/svg/Assignee.svg";

const TaskDetails = ({ onClose, taskName, taskTags, sectionTags, section, dueDate, formattedDueDate }) => {

    return (
        <>
            <div>
                <span className="task-name">{taskName}</span> {/* Display the row ID */}
                <table style={{ width: "100%", borderCollapse: "collapse" }}>
                    <tbody>
                        <tr className="task-detail-row">
                            <td className="task-detail-row-label" >Assignee</td>
                            <td className="task-detail-row-value" >
                                <ImageGallery
                                    images={[AssigneeIcon]}
                                />
                            </td>
                        </tr>
                        <tr className="task-detail-row">
                            <td className="task-detail-row-label" >Due Date</td>
                            <td className="task-detail-row-value" >
                                <div className="due-date-info">
                                    <span>{formattedDueDate || 'No due date set'}</span>
                                </div>
                            </td>
                        </tr>
                        <tr className="task-detail-row">
                            <td className="task-detail-row-label" >Section</td>
                            <td className="task-detail-row-value" >
                                <Badge
                                    className="large"   
                                    text={section.value}
                                />
                            </td>
                        </tr>
                        <tr className="task-detail-row">
                            <td className="task-detail-row-label" >Task Tags:</td>
                            <td className="task-detail-row-value" >
                                <Tags
                                    tags={taskTags || []}
                                />
                            </td>
                        </tr>
                        <tr className="task-detail-row">
                            <td className="task-detail-row-label" >Section Tags:</td>
                            <td className="task-detail-row-value" >
                                <Tags
                                    tags={sectionTags || []}
                                    disabled={true}
                                    minVisibleTags='10'
                                />
                            </td>
                        </tr>

                        {/* Add more rows as needed */}
                    </tbody>
                </table>
            </div>
        </>
    );
};

export default TaskDetails;

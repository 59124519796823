import React, { useState, useEffect, useRef } from 'react';
import './Comments.css';
import ImageGallery from '../../Atom/Image/Image';
import UserProfile from "../../../assets/images/User_profile.png";
import Up_down_arrow from "../../../assets/images/svg/Up_down_arrow.svg";
import TextArea from '../../Atom/TextArea/TextArea';
import CommentView from '../CommentView/CommentView';
import { getComments, addComment, deleteComment } from './helper';
import Button from '../../Atom/Button/Button';
import Bell from "../../../assets/images/svg/bell.svg"
import useCustomComposable from "../../utils/composables";

const Comments = ({ taskId }) => {
     const { makeUniqueId } = useCustomComposable();
    const [comments, setComments] = useState([]); // Default to an empty array
    const [newComment, setNewComment] = useState('');
    const [editingIndex, setEditingIndex] = useState(null); // Track index of the comment being edited
    const [isRecentFirst, setIsRecentFirst] = useState(true); // Manage order of comments (newest first)
    const textAreaRef = useRef(null);
    const commentsContainerRef = useRef(null); // Add this ref for scrolling

    useEffect(() => {
        const storedComments = getComments(taskId);
        setComments(storedComments ? Object.values(storedComments) : []);
        if (textAreaRef.current) textAreaRef.current.focus();
    }, [taskId]);

    // Add new useEffect for auto-scrolling
    useEffect(() => {
        if (commentsContainerRef.current) {
            const container = commentsContainerRef.current;
            if (isRecentFirst) {
                container.scrollTop = 0; // Scroll to top for newest first
            } else {
                container.scrollTop = container.scrollHeight; // Scroll to bottom for oldest first
            }
        }
    }, [comments,isRecentFirst]); // Trigger on comments change or order change

    const formatTime = (timeString) => {
        const date = new Date(timeString);
        const now = new Date();
        const diffInSeconds = Math.floor((now - date) / 1000);
        const diffInMinutes = Math.floor(diffInSeconds / 60);
        const diffInHours = Math.floor(diffInMinutes / 60);
        const diffInDays = Math.floor(diffInHours / 24);

        if (diffInSeconds < 60) return "Just now";
        if (diffInMinutes < 60) return `${diffInMinutes} minute${diffInMinutes > 1 ? 's' : ''} ago`;
        if (diffInHours < 24) return `${diffInHours} hour${diffInHours > 1 ? 's' : ''} ago`;
        return diffInDays === 1 ? "Yesterday" : `${diffInDays} day${diffInDays > 1 ? 's' : ''} ago`;
    };

    const handleCommentChange = (e) => {
        setNewComment(e.target.value);
    };
    const handleKeyDown = (e) => {
        // Check for Ctrl+Enter or Cmd+Enter (for Mac)
        if ((e.ctrlKey || e.metaKey) && e.key === 'Enter') {
            e.preventDefault(); // Prevent default behavior
            handleCommentSubmit();
        }
    };
    const handleCommentSubmit = (e) => {
        if (newComment.trim()) {
            if (editingIndex !== null) {
                const updatedComments = [...comments];
                updatedComments[editingIndex] = {
                    ...updatedComments[editingIndex],
                    message: newComment,
                    updatedAt: new Date().toISOString(),
                };

                setComments(updatedComments);
                localStorage.setItem(
                    'comments',
                    JSON.stringify({
                        [taskId]: updatedComments.reduce((acc, comment, idx) => {
                            acc[idx] = comment;
                            return acc;
                        }, {})
                    })
                );
                setEditingIndex(null);
            } else {
                // Add a new comment
                const commentData = {
                    id: makeUniqueId(),
                    message: newComment,
                    createdAt: new Date().toISOString(),
                    image: UserProfile,
                    name: "User Name",
                    updatedAt: new Date().toISOString(),
                    taskId,
                };
                addComment(taskId, commentData);
                setComments((prevComments) => [...prevComments, commentData]);
            }
            setNewComment('');
        }
    };

    const handleEditComment = (commentIndex) => {
        // Get the original index before sorting
        const originalIndex = sortedComments.findIndex(comment => comment === comments[commentIndex]);
        setEditingIndex(originalIndex);
        setNewComment(comments[originalIndex].message);
        if (textAreaRef.current) textAreaRef.current.focus();
    };

    const handleDeleteComment = (commentIndex) => {
        // Get the original index before sorting
        const originalIndex = sortedComments.findIndex(comment => comment === comments[commentIndex]);
        deleteComment(taskId, originalIndex);
        const updatedComments = getComments(taskId);
        setComments(updatedComments ? Object.values(updatedComments) : []);
    };

    // Toggle order between recent first and last first
    const toggleCommentOrder = () => {
        setIsRecentFirst(!isRecentFirst);
    };

    // Sort comments based on isRecentFirst state
    const sortedComments = [...comments].sort((a, b) => {
        const timeA = new Date(a.createdAt);
        const timeB = new Date(b.createdAt);
        return isRecentFirst ? timeB - timeA : timeA - timeB;
    });

    return (
        <div className="comments-section">
            <div className="comments-header">
                <div>Comments</div>
                <div className="toggle-order">
                    <Button
                        onClick={toggleCommentOrder}
                        type="default"
                        text={isRecentFirst ? 'Oldest First' : 'Newest First'}
                    />
                    <img
                        style={{ transform: isRecentFirst ? 'rotate(0deg)' : 'rotate(180deg)' }}
                        src={Up_down_arrow}
                        alt="Sort Order"
                    />
                </div>
            </div>
            <div className="comments-container">
                <div 
                    className="comment-view-container style-scroll" 
                    ref={commentsContainerRef}
                >
                    {sortedComments.map((comment, index) => (
                        <CommentView
                            key={index}
                            comment={comment}
                            index={index}
                            time={formatTime(comment.createdAt)}
                            onDelete={handleDeleteComment}
                            onEdit={handleEditComment}
                        />
                    ))}
                </div>
                <div className="comment">
                    <div className="profile-image">
                        <ImageGallery images={[UserProfile]} shape="circle" />
                    </div>
                    <div className='text-section'>
                        <TextArea
                            placeholder="Add a comment"
                            rows={5}
                            value={newComment}
                            onKeyDown={handleKeyDown} // Add the keydown handler here
                            onChange={handleCommentChange}
                            ref={textAreaRef}
                        />
                        <div className='comment-button'>
                        </div>
                    </div>
                </div>
                <div className="notification-icon">
                    <ImageGallery images={[Bell]} shape="circle" />
                    <Button
                        onClick={handleCommentSubmit}
                        type="primary"
                        text="Comment"
                         disabled={!newComment.trim()}
                     />
                </div>
            </div>
        </div>
    );
};

export default Comments;